<template>
  <div class="relative isolate px-6 py-24 sm:py-32 lg:px-8">
    <div
      class="absolute top-0 right-0 -z-10 lg:-translate-x-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
      aria-hidden="true"
    >
      <div
        class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-primary-600 to-secondary-400"
        style="
          clip-path: polygon(
            71.17% 91.12%,
            69.57% 40.97%,
            93.33% 45.61%,
            93.33% 9.59%,
            81.09% 29.58%,
            64.61% 27.17%,
            82.79% 9.18%,
            78.61% 1.38%,
            58.31% 3.69%,
            34.82% 84.23%,
            25.27% 3.69%,
            8.35% 18.92%,
            2.67% 40.97%,
            27.27% 45.61%,
            49.16% 1.38%,
            52.26% 24.45%,
            56.63% 63.41%
          );
        "
      />
    </div>
    <div class="mx-auto max-w-screen-2xl">
      <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 aos-init aos-animate">
        <h2 class="text-4xl font-bold tracking-tight text-white">
          {{ content?.headline }}
        </h2>
        <p class="mt-2 text-lg leading-8 text-white">
          {{ content?.description }}
        </p>
        <div class="mt-16 flex flex-col gap-16 sm:gap-y-20 lg:flex-row">
          <div
            class="lg:mt-6 lg:w-80 lg:flex-none overflow-hidden bg-white/5 shadow-2xl rounded-3xl px-6 pt-10 pb-10"
          >
            <div>
              <Image
                v-if="content?.logo?.data?.attributes"
                :image="content?.logo?.data?.attributes"
                class="w-1/2 md:w-1/2"
                :sizes="{ xs: 640, sm: 780 }"
                :img-attrs="{ class: 'rounded-full' }"
              />
            </div>
            <div class="mt-6">
              <div class="mb-5">
                <h3 class="font-bold text-lg">David Polinski</h3>
                <p class="text-sm">
                  Lassen Sie uns über Ihr neues Projekt sprechen!
                </p>
              </div>
              <div class="flex gap-x-4">
                <dt class="flex-none">
                  <span class="sr-only">Telefon</span>
                  <Icon
                    name="heroicons:phone"
                    class="h-7 w-6 text-white"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <a class="hover:text-white" :href="'tel:' + content?.phone">{{
                    content?.phone
                  }}</a>
                </dd>
              </div>
              <div class="flex gap-x-4">
                <dt class="flex-none">
                  <span class="sr-only">E-Mail</span>
                  <Icon
                    name="heroicons:envelope"
                    class="h-7 w-6 text-white"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <a
                    class="hover:text-white"
                    :href="'mailto:' + content?.email"
                    >{{ content?.email }}</a
                  >
                </dd>
              </div>
              <div>
                <dl class="mt-4 max-w-xl">
                  <div class="relative pl-8 mb-2">
                    <dt class="inline font-semibold text-white">
                      <Icon
                        name="heroicons:check-20-solid"
                        class="absolute left-1 top-1 h-5 w-5 text-primary-600"
                        aria-hidden="true"
                      />
                      Wir melden uns noch heute zurück.
                    </dt>
                  </div>
                  <div class="relative pl-8">
                    <dt class="inline font-semibold text-white">
                      <Icon
                        name="heroicons:check-20-solid"
                        class="absolute left-1 top-1 h-5 w-5 text-primary-600"
                        aria-hidden="true"
                      />
                      Wir klären Alle Ihrer Fragen.
                    </dt>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <form class="lg:flex-auto" @submit.prevent="onSubmit">
            <Alert
              v-model="concatAlert.show"
              :message="concatAlert.message"
              :type="concatAlert.type"
              class="grid-cols-2 mb-4"
            />
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  for="name"
                  class="block text-sm font-light leading-6 text-gray-300"
                  >Name</label
                >
                <div class="mt-2.5">
                  <input
                    id="name"
                    v-model="contactData.name"
                    type="text"
                    name="name"
                    autocomplete="name"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-900 bg-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  for="firma"
                  class="block text-sm font-light leading-6 text-gray-300"
                  >Unternehmen</label
                >
                <div class="mt-2.5">
                  <input
                    id="firma"
                    v-model="contactData.company"
                    type="text"
                    name="firma"
                    autocomplete="firma"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-900 bg-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  for="email"
                  class="block text-sm font-light leading-6 text-gray-300"
                >
                  E-Mail <span class="text-danger-600">*</span>
                </label>
                <div class="mt-2.5">
                  <input
                    id="email"
                    v-model="contactData.email"
                    required
                    type="email"
                    name="email"
                    autocomplete="email"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-900 bg-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  for="tel"
                  class="block text-sm font-light leading-6 text-gray-300"
                  >Telefon</label
                >
                <div class="mt-2.5">
                  <input
                    id="tel"
                    v-model="contactData.tel"
                    name="tel"
                    type="text"
                    autocomplete="tel"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-900 bg-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div class="hidden">
                <label
                  for="mobile"
                  class="block text-sm font-light leading-6 text-gray-300"
                  >Mobiltelefon</label
                >
                <div class="mt-2.5">
                  <input
                    id="mobile"
                    v-model="contactData.mobile"
                    name="Mobile"
                    type="text"
                    autocomplete="off"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-900 bg-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="message"
                  class="block text-sm font-light leading-6 text-gray-300"
                  >Erste Ideen, Ihre Nachricht</label
                >
                <div class="mt-2.5">
                  <textarea
                    id="message"
                    v-model="contactData.message"
                    name="message"
                    rows="4"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-900 bg-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div class="text-xs font-light leading-6 text-gray-400 mt-2">
              <span class="text-danger-600 text-sm">*</span> Pflichtfelder
            </div>
            <div class="mt-8 w-1/3">
              <Button type="submit" expanded> Absenden </Button>
            </div>
            <p class="mt-4 text-sm leading-6 text-white-500">
              Ich akzeptiere die
              <a href="#" class="font-semibold text-primary-600"
                >Datenschutzbestimmungen</a
              >
              beim Absenden des Formulars.
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ComponentSectionsContact } from '~/types/gql/graphql'
import createContact from '~/gql/mutations/createContact.gql'

const props = defineProps({
  content: {
    type: Object as PropType<ComponentSectionsContact>,
    required: true,
  },
})

const concatAlert = reactive({
  show: false,
  message: '',
  type: '',
})

const contactData = reactive({
  name: '',
  company: '',

  email: '',
  tel: '',
  message: '',
})

const onSubmit = () => {
  const { mutate, onDone, onError } = useMutation(
    gql`
      ${createContact}
    `,
    {
      variables: {
        data: {
          ...contactData,
        },
      },
    }
  )

  mutate()

  onDone(() => {
    contactData.name = ''
    contactData.company = ''
    contactData.email = ''
    contactData.tel = ''
    contactData.message = ''

    concatAlert.show = true
    concatAlert.message =
      'Wir werden uns in Kürze bei Ihnen melden. Vielen Dank! 🎉'
    concatAlert.type = 'success'
  })

  onError(() => {
    concatAlert.show = true
    concatAlert.message =
      'Es ist ein Fehler aufgetreten! Bitte versuchen Sie es später erneut. 😔'
    concatAlert.type = 'danger'
  })
}
</script>
